import React from "react";
import { FiLogOut, FiFolderPlus, FiSearch, FiBook } from "react-icons/fi";
import NavigationLink from "../Atoms/NavigationLink";
import { logOut } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
const Sidebar = () => {
  const navigate = useNavigate();
  return (
    <div className="w-3/12 h-full  border-r  rounded overflow-hidden">
      <div className="h-screen border-r fixed w-3/12 bg-green">
        <div className="p-10 bg-white flex items-center justify-center">
          <img src="/Logo.png" alt="Logo" />
        </div>
        <nav className="h-4/6  bg-green h-full rounded flex flex-col  justify-between">
          <ul className="w-10/12 mx-auto py-2 ">
            <li className="w-11/12 mx-auto my-4">
              <NavigationLink url="/">
                <span className="text-xl mr-2">
                  <FiSearch />
                </span>
                <span>Busqueda Fonetica</span>
              </NavigationLink>
            </li>
            <li className="w-11/12 mx-auto my-4">
              <NavigationLink url="marcas">
                <span className="text-xl mr-2">
                  <FiBook />
                </span>
                <span>Todas las marcas</span>
              </NavigationLink>
            </li>
            <li className="w-11/12 mx-auto my-4">
              <NavigationLink url="crear">
                <span className="text-xl mr-2">
                  <FiFolderPlus />
                </span>
                <span>Importar Marcas</span>
              </NavigationLink>
            </li>
          </ul>
          <div className=" justify-self-end">
            <button
              onClick={(e) => {
                e.preventDefault();
                logOut()
                  .then((res) => {
                    if (res.status === 200) {
                      navigate("/login");
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
              className="flex items-center text-white mx-auto justify-start p-2 w-9/12 rounded-lg hover:font-semibold "
            >
              <span className="text-xl mr-2">
                <FiLogOut />
              </span>
              <span>Cerrar sesion</span>
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;

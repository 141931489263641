import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../Organisms/Sidebar";

const Layout = () => {
  const navigate = useNavigate();
  const auth = localStorage.getItem("AuthToken");

  //!Replace this method it redirects on every reaload even it
  //! is in the correct page
  useEffect(() => {
    if (!auth) {
      navigate("/login");
    }
  }, [auth]);

  return (
    <main className="flex min-h-screen  w-full ">
      <Sidebar />
      <section className="w-9/12 h-full">
        <div className="w-12/12 lg:w-10/12  mx-auto borde p-1">
          <Outlet />
        </div>
      </section>
    </main>
  );
};

export default Layout;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditBrandForm from "../components/Organisms/EditBrandForm";
import { API_ROUTE } from "./../utils/config";
const GazetaPage = () => {
  const [brand, setBrand] = useState({});
  const [loadingStatus, setLoadingStatus] = useState("idle");
  const getBrand = async (id) => {
    try {
      setLoadingStatus("loading");
      const res = await axios.get(`${API_ROUTE}brands/${id}`);
      setLoadingStatus("loaded");
      const brandData = res.data;
      setBrand(brandData);
    } catch (error) {
      setLoadingStatus("error");
    }
  };
  const { id } = useParams();
  useEffect(() => {
    getBrand(id);
  }, [id]);

  return (
    <section className="py-10">
      <EditBrandForm brandData={brand} id={id} loadingStatus={loadingStatus} />
    </section>
  );
};

export default GazetaPage;

import React from "react";
import { InstantSearch } from "react-instantsearch-hooks-web";
import PhoneticSearchForm from "../components/Organisms/PhoneticSearchForm";
import algoliasearch from "algoliasearch";
import PhoneticResultTable from "../components/Organisms/PhoneticResultTable";

// import BrandsTable from "../components/Organisms/BrandsTable";
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_WRITE_KEY
);

const Dashboard = () => {
  //esta pagina es de inicio y busqueda fonetica
  return (
    <div className="h-screen p-10">
      <h1 className="text-2xl font-medium text-gray-700 text-primary ">
        Busqueda Fonetica
      </h1>
      <InstantSearch searchClient={searchClient} indexName={"igb_marcas"}>
        <PhoneticSearchForm />
        <div className="py-4">
          <PhoneticResultTable client={searchClient} />
        </div>
      </InstantSearch>
    </div>
  );
};

export default Dashboard;

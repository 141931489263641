import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from "@mui/material";
import React from "react";
import { Pagination, useHits } from "react-instantsearch-hooks-web";
import PhoneticResultRow from "../Molecules/PhoneticResultRow";

const PhoneticResultTable = (props) => {
  const { hits } = useHits(props);
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell align="center">
              <span className="text-primary">Denominación</span>
            </TableCell>
            <TableCell align="center">
              <span className="text-primary">Porcentaje de similitud</span>
            </TableCell>
          </TableHead>
          <TableBody>
            {hits.map((hit) => {
              return <PhoneticResultRow hit={hit} key={hit.objectID} />;
            })}
          </TableBody>
        </Table>
        <div className="flex bg-slate-50 border justify-center p-2">
          {/* <CustomPagination /> */}
          <Pagination
            classNames={{
              list: "flex",
              item: "mx-2 flex h-8 w-8 items-center justify-center rounded-full overflow-hidden hover:bg-gray-200",
              selectedItem: "bg-primary text-white",
              link: "h-full w-full flex items-center justify-center ",
            }}
          />
        </div>
      </TableContainer>
    </>
  );
};

export default PhoneticResultTable;

import { Alert, Snackbar } from "@mui/material";
import React, { useState } from "react";

const CustomSnackBar = ({ message, display }) => {
  const [open, setopen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopen((open) => !open);
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={3}
      handleClose={handleClose}
    >
      <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        This is a success message!
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackBar;

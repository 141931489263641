import {
  Checkbox,
  IconButton,
  Popover,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { Highlight, useInstantSearch } from "react-instantsearch-hooks-web";
import { deleteBrand } from "../../utils/brands";
import BrandOptionGroup from "./BrandOptionGroup";

const BrandTableRow = ({ hit, hitsSelected, selectOne }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { refresh } = useInstantSearch();
  const { objectID } = hit;
  //this method helps clear cache after a change
  const handleClickPopOver = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Checkbox
          checked={hitsSelected.includes(hit.objectID) ? true : false}
          onClick={(e) => {
            selectOne(objectID);
          }}
        />
        <Highlight attribute={"Denominacion"} hit={hit} />
      </TableCell>
      <TableCell align="right">
        <Highlight attribute={"Tramite"} hit={hit} />
      </TableCell>
      <TableCell align="right">{hit.Clase}</TableCell>
      <TableCell align="right">{hit.Gazeta}</TableCell>
      <TableCell align="right">{hit.Pagina}</TableCell>
      <TableCell align="right">
        <IconButton onClick={handleClickPopOver}>
          <FiMoreVertical />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          elevation={1}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <BrandOptionGroup
            id={objectID}
            deleteBrand={deleteBrand}
            refresh={refresh}
          />
        </Popover>
      </TableCell>
    </TableRow>
  );
};

export default BrandTableRow;

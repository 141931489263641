import React from "react";
import { InstantSearch } from "react-instantsearch-hooks-web";
import algoliasearch from "algoliasearch";
import SearchBox from "../components/Molecules/SearchBox";
import BrandsTable from "../components/Organisms/BrandsTable";
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_WRITE_KEY
);

const Marcas = () => {
  return (
    <div className="p-4">
      <h1 className="text-2xl mt-10 text-primary font-medium text-primary ">
        Marcas
      </h1>

      <div className="my-4">
        <InstantSearch searchClient={searchClient} indexName={"igb_marcas"}>
          <SearchBox />
          <BrandsTable client={searchClient} />
        </InstantSearch>
      </div>
    </div>
  );
};

export default Marcas;

import {
  Box,
  Button,
  ButtonGroup,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { RiLoader4Fill } from "react-icons/ri";
import { deleteBrand, updateBrand } from "../../utils/brands";
import React, { useEffect, useState } from "react";
import { FiSave, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
const EditBrandForm = ({ brandData, loadingStatus, id }) => {
  const [data, setData] = useState({
    Denominacion: brandData.denominacion,
    Clase: brandData.clase,
    Pagina: brandData.pagina,
    Gazeta: brandData.gazeta,
  });
  useEffect(() => {
    setData(brandData);
  }, [brandData]);
  const navigate = useNavigate();
  return (
    <Box component={Paper} className="px-4 py-2 w-7/12 mx-auto border min-h-70">
      {loadingStatus === "loaded" ? (
        <form className="">
          <Typography variant="h6" className="text-primary" align="center">
            {id}
          </Typography>
          <div className="flex justify-around my-4">
            <TextField
              defaultValue={brandData.Denominacion}
              label="Denominacion"
              onChange={(e) => {
                setData({ ...data, Denominacion: e.target.value });
              }}
            />
            <TextField
              type="number"
              defaultValue={brandData.Clase}
              label="Clase"
              onChange={(e) => {
                setData({ ...data, Clase: e.target.value });
              }}
            />
          </div>
          <div className="flex justify-around my-2">
            <TextField
              defaultValue={brandData.Gazeta}
              type="number"
              label="Gaceta"
              onChange={(e) => {
                setData({ ...data, Gazeta: e.target.value });
              }}
            />
            <TextField
              type={"number"}
              defaultValue={brandData.Pagina}
              label="Pagina"
              onChange={(e) => {
                setData({ ...data, Pagina: e.target.value });
              }}
            />
          </div>
          <div className="flex justify-end p-4">
            <ButtonGroup
              orientation="vertical"
              variant="outlined"
              aria-label="Opciones"
            >
              <Button
                color="error"
                startIcon={<FiTrash2 />}
                onClick={() => {
                  deleteBrand(id);
                }}
              >
                Eliminar Marca
              </Button>
              <Button
                startIcon={<FiSave />}
                onClick={() => {
                  updateBrand(id, { ...data, objectID: id, Tramite: id });
                  navigate("/marcas");
                }}
              >
                Guardar Cambios
              </Button>
            </ButtonGroup>
          </div>
        </form>
      ) : (
        <div className="h-64 flex flex-col justify-center items-center  rounded-xl">
          <span className="text-5xl text-primary animate-spin">
            <RiLoader4Fill />
          </span>
          <span className="text-lg">Loading</span>
        </div>
      )}
    </Box>
  );
};

export default EditBrandForm;

import React from "react";
import CustomSearchBox from "./CustomSearchBox";
import { Box } from "@mui/material";
import CustomSelect from "./CustomSelect";
const SearchBox = () => {
  return (
    <Box className="border p-4 rounded-xl my-5 flex justify-around">
      <div className="w-5/12 ">
        <CustomSearchBox />
      </div>
      <div className="w-3/12">
        <CustomSelect attribute="Clase" label="Clase" />
      </div>
      <div className="w-3/12">
        <CustomSelect attribute="Gazeta" label="Gaceta" />
      </div>
    </Box>
  );
};

export default SearchBox;

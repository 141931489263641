import React from "react";
import CustomSearchBox from "../Molecules/CustomSearchBox";
import CustomSelect from "../Molecules/CustomSelect";

const PhoneticSearchForm = ({ searchTermCB }) => {
  return (
    <form
      action=""
      className="w-full  bg-white rounded-xl border my-6 flex flex-col items-end p-2"
    >
      <div className="flex justify-around w-full my-3">
        <div className="w-6/12 ">
          <CustomSearchBox searchTermCB={searchTermCB} />
        </div>
        <div className="w-3/12">
          <CustomSelect attribute="Gazeta" label="Gazeta" />
        </div>
      </div>
    </form>
  );
};

export default PhoneticSearchForm;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LogInForm from "../components/Organisms/LogInForm";

const LogIn = () => {
  const navigate = useNavigate();
  const auth = localStorage.getItem("AuthToken");

  useEffect(() => {
    if (auth) {
      navigate("/");
    }
  }, [auth]);
  return (
    <main className="flex justify-center flex-col items-center h-screen">
      <img src="/Logo.png" alt="Logo" className="w-28 my-5" />
      <LogInForm />
    </main>
  );
};

export default LogIn;

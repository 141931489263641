import React, { useState } from "react";
import { submitBrands } from "../../utils/brands";
import { read, utils } from "xlsx";
import { Alert, Button, Snackbar, TextField } from "@mui/material";
import { BiLoaderAlt } from "react-icons/bi";

const UploadBrandsForm = () => {
  const [gaceta, setGaceta] = useState();
  const [brandsBatch, setBrandsBatch] = useState([]);
  const [status, setStatus] = useState({});
  const formatData = (brandsBatch) => {
    const formatedData = brandsBatch.map((item) => {
      return {
        Denominacion: item.Denominacion.toString().trim(),
        Clase: item.Clase,
        Pagina: item.Pagina,
        Gazeta: item.Gazeta ? item.Gazeta : gaceta,
        objectID: item.Tramite.toString().trim(),
        Tramite: item.Tramite.toString().trim(),
      };
    });
    setBrandsBatch(formatedData);
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        formatData(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  return (
    <div className="border w-6/12 shadow-lg rounded-xl mx-auto p-4">
      <form
        className="w-full mx-auto"
        onSubmit={(e) => {
          e.preventDefault();
          setStatus({ status: "loading", error: null });
          submitBrands(brandsBatch).then((res) => {
            if (res.status === 200) {
              setStatus({ status: "success", error: null, open: true });
            } else {
              setStatus({ status: "error", error: res, open: true });
            }
          });
        }}
      >
        <div className="my-3">
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            label="Numero de gaceta"
            onChange={(e) => {
              setGaceta((gaceta) => {
                gaceta = e.target.value;
              });
            }}
          />
        </div>
        <div className="my-3">
          <label htmlFor="file" className="text-black">
            Subir un archivo con formato xlsx:
          </label>
          <input
            type="file"
            name="file"
            id="file"
            className="bg-blue-50 w-full  p-2 rounded-xl"
            onChange={(e) => {
              setBrandsBatch([]);
              readUploadFile(e);
            }}
          />
        </div>
        {brandsBatch.length > 0 ? (
          <Alert severity="info">{`${brandsBatch.length} marcas listas para subir.`}</Alert>
        ) : null}
        <div className="my-3">
          <Button
            fullWidth
            variant="contained"
            disabled={status.status === "loading" ? true : false}
            className="h-10 "
          >
            {status.status !== "loading" ? (
              <input
                type="submit"
                value="Subir"
                className=" absolute w-full cursor-pointer"
              />
            ) : (
              <span className="text-center text-lg animate-spin">
                <BiLoaderAlt />
              </span>
            )}
          </Button>
        </div>
      </form>
      <Snackbar
        open={status.open ? true : false}
        autoHideDuration={3000}
        onClose={() => {
          setStatus({ open: false });
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity={status.status === "success" ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {status.error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UploadBrandsForm;

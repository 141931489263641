import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const BrandOptionGroup = ({ deleteBrand, id, refresh }) => {
  const navigate = useNavigate();

  return (
    <ButtonGroup
      orientation="vertical"
      aria-label="vertical contained button group"
      variant="text"
    >
      <Button
        key="one"
        variant="contained"
        color="primary"
        onClick={() => {
          navigate(`/marcas/${id}`);
        }}
        startIcon={<FiEdit2 />}
      >
        Editar
      </Button>
      <Button
        key="two"
        variant="contained"
        color="primary"
        onClick={() => {
          deleteBrand(id);
          refresh();
        }}
        startIcon={<FiTrash2 />}
      >
        Eliminar
      </Button>
    </ButtonGroup>
  );
};

export default BrandOptionGroup;

import { TableCell, TableRow } from "@mui/material";
import React from "react";
import * as dljs from "damerau-levenshtein-js";
import { Highlight } from "react-instantsearch-hooks-web";

const PhoneticResultRow = ({ hit, term }) => {
  let editDistance = dljs.distance(
    hit.Denominacion.toLowerCase(),
    hit._highlightResult.Denominacion.matchedWords
      .toString()
      .replace(",", " ")
      .toLowerCase()
  );
  const similarity = Math.round(
    (1 - editDistance / hit.Denominacion.length) * 100
  );
  return (
    <TableRow>
      <TableCell>
        <Highlight attribute={"Denominacion"} hit={hit} />
      </TableCell>
      <TableCell align="center">
        <span className="text-blue-600 font-bold">{similarity + "%"}</span>
      </TableCell>
    </TableRow>
  );
};

export default PhoneticResultRow;

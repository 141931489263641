import React from "react";
import { NavLink } from "react-router-dom";
const NavigationLink = (props) => {
  return (
    <NavLink
      to={props.url}
      className={({ isActive }) =>
        "text-white flex justify-left p-2  rounded-lg items-center transition-all ease-in duration-100 hover:bg-primaryHover " +
        (isActive && "bg-primaryHover font-semibold")
      }
    >
      {props.children}
    </NavLink>
  );
};

export default NavigationLink;

import { MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import { useMenu } from "react-instantsearch-hooks-web";

const CustomSelect = (props) => {
  const { items, refine } = useMenu({
    ...props,
    limit: 200,
    sortBy: ["isRefined:asc"],
  });
  const [value, setValue] = useState("");
  return (
    <TextField
      id="filled-select-currency"
      select
      fullWidth
      size="medium"
      value={value}
      label={props.label}
      onChange={(e) => {
        refine(e.target.value);
        setValue(e.target.value);
      }}
      variant="outlined"
    >
      <MenuItem key="" value="">
        All
      </MenuItem>
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CustomSelect;

import React from "react";
import UploadBrandsForm from "../components/Organisms/UploadBrandsForm";

const Crear = () => {
  return (
    <div className="h-screen p-4 ">
      <div className="flex">
        <h1 className="text-2xl text-primary font-medium">Importar Marcas</h1>
      </div>
      <div className=" p-4 rounded-xl w-full flex flex-col">
        <UploadBrandsForm />
      </div>
    </div>
  );
};

export default Crear;

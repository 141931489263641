import { createContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layouts/Layout";
import Crear from "./Pages/Crear";
import Dashboard from "./Pages/Dashboard";
import SingleBrandPage from "./Pages/SingleBrandPage";
import Marcas from "./Pages/Marcas";
import LogIn from "./Pages/LogIn";
function App() {
  const auth = window.localStorage.getItem("AuthToken");
  const [loggedIn, setLoggedIn] = useState();
  useEffect(() => {
    if (auth) {
      setLoggedIn(true);
    }
  }, [auth]);
  const AuthContext = createContext(loggedIn);
  return (
    <div className="App">
      <AuthContext.Provider>
        <Routes>
          <Route path="/login" element={<LogIn />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="marcas" element={<Marcas />} />
            <Route path="marcas/:id" element={<SingleBrandPage />} />
            <Route path="crear" element={<Crear />} />
          </Route>
        </Routes>
      </AuthContext.Provider>
    </div>
  );
}

export default App;

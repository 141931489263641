import React, { useState } from "react";
import { Pagination, useHits } from "react-instantsearch-hooks-web";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Checkbox } from "@mui/material";
import { deleteSelectedBrands } from "../../utils/brands";
import { useInstantSearch } from "react-instantsearch-hooks-web"; //TODO Create methods to handle edit and delete operations
import BrandTableRow from "../Molecules/BrandTableRow";
import CustomSnackBar from "../Molecules/CustomSnackBar";
const BrandsTable = (props) => {
  const [selectedAll, setSelectedAll] = useState(true);
  const [hitsSelected, setHitsSelected] = useState([]);
  const { hits } = useHits(props);
  const { refresh } = useInstantSearch();

  //Method to select all brands
  const selectAll = () => {
    setSelectedAll((selectedAll) => !selectedAll);
    if (selectedAll) {
      const hitsId = hits.map((hit) => {
        return hit.objectID;
      });
      setHitsSelected(hitsId);
    } else {
      setHitsSelected([]);
    }
  };
  //Select one brand
  const selectOne = (objectID) => {
    if (!hitsSelected.includes(objectID)) {
      setHitsSelected([...hitsSelected, objectID]);
    } else {
      setHitsSelected(hitsSelected.filter((item) => item !== objectID));
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onClick={() => {
                    selectAll();
                  }}
                />
                <span className="text-primary">Denominacion</span>
              </TableCell>
              <TableCell align="right">
                <span className="text-primary">Tramite</span>
              </TableCell>
              <TableCell align="right">
                <span className="text-primary">Clase</span>
              </TableCell>
              <TableCell align="right">
                <span className="text-primary">Gaceta</span>
              </TableCell>
              <TableCell align="right">
                <span className="text-primary">Pagina</span>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hits.map((hit) => (
              <BrandTableRow
                key={hit.objectID}
                hit={hit}
                hitsSelected={hitsSelected}
                selectOne={selectOne}
              />
            ))}
          </TableBody>
        </Table>
        <div className="flex bg-slate-50 border justify-center p-2">
          {/* <CustomPagination /> */}
          <Pagination
            classNames={{
              list: "flex",
              item: "mx-2 flex h-8 w-8 items-center justify-center rounded-full overflow-hidden hover:bg-gray-200",
              selectedItem: "bg-primary text-white",
              link: "h-full w-full flex items-center justify-center ",
            }}
          />
        </div>
      </TableContainer>
      {hitsSelected.length > 0 && (
        <Button
          onClick={() => {
            deleteSelectedBrands(hitsSelected);
            refresh();
          }}
        >
          Delete Selection
        </Button>
      )}
      <CustomSnackBar />
    </>
  );
};
export default BrandsTable;

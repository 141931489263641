import { TextField } from "@mui/material";
import React from "react";
import { useSearchBox } from "react-instantsearch-hooks-web";
const CustomSearchBox = (props) => {
  const { query, refine, clear, isSearchStalled } = useSearchBox(props);
  const { searchTermCB } = props;

  return (
    <TextField
      id="filled-search"
      size="medium"
      fullWidth
      label="Denominacion"
      type="search"
      variant="outlined"
      onChange={(e) => {
        refine(e.target.value);
      }}
    />
  );
};

export default CustomSearchBox;

import axios from "axios";
import { API_ROUTE } from "../utils/config";
//deleteOneBrand
export const deleteBrand = async (id) => {
  axios
    .delete(`${API_ROUTE}brands/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
//deleteMultipleBrands
export const deleteSelectedBrands = async (brands) => {
  const data = JSON.stringify({
    items: brands,
  });
  var config = {
    method: "delete",
    url: `${API_ROUTE}gazeta`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const res = await axios(config);
  return res;
};
//update one brand
export const updateBrand = async (id, data) => {
  const formatedData = JSON.stringify(data);
  var config = {
    method: "put",
    url: `${API_ROUTE}brands/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: formatedData,
  };

  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

//Upload many brands
export const submitBrands = async (data) => {
  if (data.length > 0) {
    try {
      const res = await axios.post(`${API_ROUTE}gazeta`, data);
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  } else {
    return "There are not brands to submit";
  }
};

import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { logIn } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
const LogInForm = () => {
  const [userData, setUserData] = useState({});
  const [status, setStatus] = useState({});
  const navigate = useNavigate();

  return (
    <form
      className=" border flex flex-col w-6/12 sm:w-5/12 lg:w-3/12 shadow-lg py-6 px-4 rounded-xl"
      onSubmit={(e) => {
        e.preventDefault();
        setStatus({ status: "loading", error: null });
        logIn(userData).then((res) => {
          if (res.status === 200) {
            setStatus({ status: "success", error: null });
            navigate("/");
          } else {
            setStatus({ status: "error", error: res.error });
          }
        });
      }}
    >
      <div className="w-full my-3">
        <TextField
          fullWidth
          disabled={status === "loading" ? true : false}
          variant="filled"
          type={"email"}
          label="Email"
          onChange={(e) => {
            setUserData({ ...userData, email: e.target.value });
          }}
        />
      </div>
      <div className="my-3">
        <TextField
          fullWidth
          variant="filled"
          disabled={status.status === "loading" ? true : false}
          type={"password"}
          label="Contraseña"
          onChange={(e) => {
            setUserData({ ...userData, password: e.target.value });
          }}
        />
      </div>
      <Button
        disabled={status.status === "loading" ? true : false}
        variant={"contained"}
        color="info"
        size="large"
        className="h-10"
      >
        <input
          type="submit"
          className="w-full  absolute cursor-pointer"
          value={"Ingresar"}
        />
      </Button>
    </form>
  );
};

export default LogInForm;

import axios from "axios";
import { API_ROUTE } from "../utils/config";
export const logOut = async () => {
  try {
    const res = await axios.delete(`${API_ROUTE}login`);
    window.localStorage.removeItem("AuthToken");
    return res;
  } catch (error) {
    return error;
  }
};

export const logIn = async (userData) => {
  try {
    const res = await axios.post(`${API_ROUTE}login`, userData);
    localStorage.setItem("AuthToken", `Bearer ${res.data.token}`);
    return res;
  } catch (error) {
    return error;
  }
};
// export const verifyUser = async () => {};
